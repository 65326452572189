import {
  ArrowBack,
  ArrowForward,
  Cancel,
  Edit,
  ErrorOutline,
  Share,
  TaskAlt,
} from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "react-admin";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { DisplayAlertWrapper } from "./DisplayAlertWrapper";
import { useDrawer } from "../../../../common/SlideoutDrawer/DrawerContext";
import "../../alerts.scss";
import { apiAlertClient } from "../../../../common/api-client";
import { AuthStateContext } from "../../../../context";
import { BulletinResourceResponse, IUserPermission } from "../../../../types";
import { parseUserPermissions } from "../../../UserManagement/utils";
import ShareDialog from "../../ShareDialog";
import { DisplayAlert } from "../../add/DisplayAlert";
import ResolveBulletin from "../../add/DisplayAlert/ResolveBulletin";
import { ALERT_STATUS } from "../../constants";
import {
  AlertViewContext,
  SingleAlertContext,
  SingleAlertProvider,
} from "../../context";
import { updateToContextStructure } from "../../utils";

interface DisplayAlertDrawerProps {
  mode: ALERT_STATUS;
  queryNextPage?: () => void;
}

export const DisplayAlertDrawer = ({
  mode,
  queryNextPage = () => {},
}: DisplayAlertDrawerProps) => {
  return (
    <div
      className="w-100 d-flex flex-row justify-content-around h-100"
      style={{
        alignItems: "flex-start",
      }}
    >
      <SingleAlertProvider>
        <DisplayAlertWrapper
          display_type={"main"}
          mode={mode}
          queryNextPage={queryNextPage}
        />
      </SingleAlertProvider>
      <SingleAlertProvider>
        <DisplayAlertWrapper display_type={"compare"} mode={mode} />
      </SingleAlertProvider>
    </div>
  );
};
