import { Cancel, Check, Clear, Remove } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";

import { reportColourTypes, statusTypes } from "../../../types/typesReportV2";
import {
  DateRangePickerCustom,
  FilterChip,
  RenderFilterChips,
  SearchBarFilters,
} from "../../alerts/Search/SearchFilters";
import { OnlineReportingContext } from "../context";

const convertDate = (date: string | null) => {
  return date ? new Date(date).toISOString().split("T")[0] : "";
};
const toDateString = (date: string | null) => {
  return date ? new Date(date).toISOString() : "";
};
export interface IReportListFilterOptions {
  creationStartDate: string | null;
  creationEndDate: string | null;
  updateStartDate: string | null;
  updateEndDate: string | null;
  reportStatus: string | null;
  trackingNumber?: string | null;
  reportType?: string | null;
}

export const reportFilterConfig = (filters: IReportListFilterOptions) => {
  return [
    {
      title: "Creation Start Date",
      urlParam: "creationStartDate",
      value: filters.creationStartDate,
      displayFullLabel: true,
      customChipDisplay: (value) => convertDate(value),
    },
    {
      title: "Creation End Date",
      urlParam: "creationEndDate",
      value: filters.creationEndDate,
      displayFullLabel: true,
      customChipDisplay: (value) => convertDate(value),
    },
    {
      title: "Update Start Date",
      urlParam: "updateStartDate",
      value: filters.updateStartDate,
      displayFullLabel: true,
      customChipDisplay: (value) => convertDate(value),
    },
    {
      title: "Update End Date",
      urlParam: "updateEndDate",
      value: filters.updateEndDate,
      displayFullLabel: true,
      customChipDisplay: (value) => convertDate(value),
    },
    {
      title: "Report Status",
      urlParam: "reportStatus",
      value: filters.reportStatus,
      displayFullLabel: true,
      customChipDisplay: (value) => statusTypes[value],
    },
    {
      title: "Tracking Number",
      urlParam: "trackingNumber",
      value: filters.trackingNumber,
      displayFullLabel: true,
    },
    {
      title: "Report Type",
      urlParam: "reportType",
      value: filters.reportType,
      displayFullLabel: true,
    },
  ];
};

export const RenderReportFilterChips = ({
  filters,
  setFilters,
}: {
  filters: IReportListFilterOptions;
  setFilters: (IReportListFilterOptions) => void;
}) => {
  const filterConfig = reportFilterConfig(filters);

  const handleRemoveFilter = (urlParam: string, valueToRemove?: string) => {
    if (urlParam === "reportStatus" && filters.reportStatus && valueToRemove) {
      const remainingStatuses = filters.reportStatus
        .split(",")
        .filter((status) => status !== valueToRemove)
        .join(",");
      setFilters({
        ...filters,
        reportStatus: remainingStatuses.length > 0 ? remainingStatuses : null,
      });
    } else {
      setFilters({
        ...filters,
        [urlParam]: null,
      });
    }
  };

  const handleClearAll = () => {
    setFilters({
      creationStartDate: null,
      creationEndDate: null,
      updateStartDate: null,
      updateEndDate: null,
      reportStatus: null,
      trackingNumber: null,
      reportType: null,
    });
  };

  const activeFilters = filterConfig.filter((filter) => filter.value !== null);

  if (activeFilters.length === 0) return null;

  const handleClearAllReportStatuses = () => {
    setFilters({
      ...filters,
      reportStatus: null,
    });
  };

  return (
    <RenderFilterChips
      filterChipsActive={activeFilters.map((filter) => {
        if (filter.urlParam === "reportStatus" && filter.value) {
          const statusArray = filter.value.split(",");
          return (
            <Stack
              key={filter.urlParam}
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
                paddingX: 1,
                paddingY: 0.5,
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderRadius: "16px",
              }}
            >
              <Button
                size="small"
                onClick={handleClearAllReportStatuses}
                color="secondary"
                startIcon={<Cancel />}
                sx={{ minWidth: 0, padding: 0, margin: 0 }}
              />
              <Typography variant="body2">Status</Typography>
              <Stack direction="row" spacing={1}>
                {statusArray.map((status) => (
                  <Chip
                    key={status}
                    label={statusTypes[status]}
                    size="small"
                    onDelete={() => handleRemoveFilter(filter.urlParam, status)}
                    color={reportColourTypes[status] || "default"}
                  />
                ))}
              </Stack>
            </Stack>
          );
        } else {
          return (
            <FilterChip
              key={filter.urlParam}
              title={filter.title}
              value={filter.value ?? ""}
              onRemove={() => handleRemoveFilter(filter.urlParam)}
              displayFullLabel={filter.displayFullLabel}
              customChipValueDisplay={(value) =>
                filter.customChipDisplay
                  ? filter.customChipDisplay(value)
                  : value
              }
            />
          );
        }
      })}
      onClearAll={handleClearAll}
    />
  );
};

const ReportFilters = ({
  isOpen,
  anchorEl,
  onClose,
  filters,
  setFilters,
}: {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  filters: IReportListFilterOptions;
  setFilters: (IReportListFilterOptions) => void;
}) => {
  const { reportTypeNames } = useContext(OnlineReportingContext);

  const gridElements = [
    <Grid item xs={6} key="tracking-number">
      <TextField
        label="Tracking Number"
        variant="outlined"
        fullWidth
        value={filters.trackingNumber || ""}
        onChange={(ev) => {
          setFilters({
            ...filters,
            trackingNumber: ev.target.value,
          });
        }}
        InputProps={{
          endAdornment: filters.trackingNumber ? (
            <IconButton
              size="small"
              onClick={() => {
                setFilters({
                  ...filters,
                  trackingNumber: null,
                });
              }}
            >
              <Clear />
            </IconButton>
          ) : null,
        }}
      />
    </Grid>,
    <Grid item xs={12} key="creation-start-date">
      <DateRangePickerCustom
        labelFrom="Creation Start Date"
        labelTo="Creation End Date"
        startDate={convertDate(filters.creationStartDate)}
        endDate={convertDate(filters.creationEndDate)}
        onStartChange={(value) =>
          setFilters({
            ...filters,
            creationStartDate: value ? toDateString(value) : null,
          })
        }
        onEndChange={(value) => {
          setFilters({
            ...filters,
            creationEndDate: value ? toDateString(value) : null,
          });
        }}
      />
    </Grid>,
    <Grid item xs={12} sm={12} key="update-start-date">
      <DateRangePickerCustom
        labelFrom="Update Start Date"
        labelTo="Update End Date"
        startDate={convertDate(filters.updateStartDate)}
        endDate={convertDate(filters.updateEndDate)}
        onStartChange={(value) => {
          setFilters({
            ...filters,
            updateStartDate: value ? toDateString(value) : null,
          });
        }}
        onEndChange={(value) => {
          setFilters({
            ...filters,
            updateEndDate: value ? toDateString(value) : null,
          });
        }}
      />
    </Grid>,

    <Grid item xs={6} key="report-status">
      <Autocomplete
        id="select-report-status"
        multiple
        options={
          filters.reportStatus && filters.reportStatus.length > 0
            ? ["unselect_all", ...Object.keys(statusTypes)]
            : Object.keys(statusTypes)
        }
        value={filters.reportStatus ? filters.reportStatus.split(",") : []}
        getOptionLabel={(option) =>
          option === "unselect_all" ? "Unselect All" : statusTypes[option]
        }
        autoComplete
        autoHighlight
        clearOnBlur
        openOnFocus
        size="small"
        disableCloseOnSelect
        onChange={(event, newValue) => {
          if (newValue.includes("unselect_all")) {
            setFilters({
              ...filters,
              reportStatus: null,
            });
          } else {
            setFilters({
              ...filters,
              reportStatus: newValue.length > 0 ? newValue.join(",") : null,
            });
          }
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {option === "unselect_all" ? (
                <IconButton size="small">
                  <Remove />
                </IconButton>
              ) : (
                <Checkbox
                  icon={<span />}
                  checkedIcon={<Check />}
                  checked={selected}
                  size="small"
                  sx={{ padding: 0 }}
                />
              )}
              {option === "unselect_all" ? "Unselect All" : statusTypes[option]}
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Report Status"
            fullWidth
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              label={statusTypes[option]}
              {...getTagProps({ index })}
              color={reportColourTypes[option]}
              size="small"
            />
          ))
        }
      />
    </Grid>,
    <Grid item xs={6} key="report-type">
      <Autocomplete
        id="select-report-type"
        options={reportTypeNames}
        value={filters.reportType}
        getOptionLabel={(option) => option}
        autoComplete
        autoHighlight
        clearOnBlur
        openOnFocus
        size="small"
        onChange={(event, newValue) => {
          if (newValue) {
            setFilters({
              ...filters,
              reportType: newValue,
            });
          } else {
            setFilters({
              ...filters,
              reportType: null,
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Report Type"
            fullWidth
          />
        )}
      />
    </Grid>,
    <Grid item xs={12} key="clear-filters">
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => {
          setFilters({
            creationStartDate: null,
            creationEndDate: null,
            updateStartDate: null,
            updateEndDate: null,
            reportStatus: null,
            trackingNumber: null,
            reportType: null,
          });
        }}
      >
        Clear Filters
      </Button>
    </Grid>,
  ];

  return (
    <SearchBarFilters
      isOpen={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      gridElements={gridElements}
    />
  );
};

export default ReportFilters;
