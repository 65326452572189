/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetM2mClientRequest } from '../models/GetM2mClientRequest';
import type { GetM2mClientResponse } from '../models/GetM2mClientResponse';
import type { PostM2mClientRequest } from '../models/PostM2mClientRequest';
import type { PostM2mClientResponse } from '../models/PostM2mClientResponse';
import type { PostM2mClientRotateCancelRequest } from '../models/PostM2mClientRotateCancelRequest';
import type { PostM2mClientRotateCancelResponse } from '../models/PostM2mClientRotateCancelResponse';
import type { PostM2mClientRotateRequest } from '../models/PostM2mClientRotateRequest';
import type { PostM2mClientRotateResponse } from '../models/PostM2mClientRotateResponse';
import type { PostM2mClientRotateStartRequest } from '../models/PostM2mClientRotateStartRequest';
import type { PostM2mClientRotateStartResponse } from '../models/PostM2mClientRotateStartResponse';
import type { PutM2mClientRequest } from '../models/PutM2mClientRequest';
import type { PutM2mClientResponse } from '../models/PutM2mClientResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns PostM2mClientResponse
     * @throws ApiError
     */
    public postM2MClient(
        requestBody: PostM2mClientRequest,
    ): CancelablePromise<PostM2mClientResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/m2m_client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns PutM2mClientResponse
     * @throws ApiError
     */
    public putM2MClient(
        requestBody: PutM2mClientRequest,
    ): CancelablePromise<PutM2mClientResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/auth/m2m_client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clientId
     * @param requestBody
     * @returns GetM2mClientResponse
     * @throws ApiError
     */
    public getM2MClient(
        clientId: string,
        requestBody: GetM2mClientRequest,
    ): CancelablePromise<GetM2mClientResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/m2m_client/{client_id}',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clientId
     * @param requestBody
     * @returns PostM2mClientRotateResponse
     * @throws ApiError
     */
    public postM2MClientRotate(
        clientId: string,
        requestBody: PostM2mClientRotateRequest,
    ): CancelablePromise<PostM2mClientRotateResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/m2m_client/{client_id}/rotate',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clientId
     * @param requestBody
     * @returns PostM2mClientRotateCancelResponse
     * @throws ApiError
     */
    public postM2MClientRotateCancel(
        clientId: string,
        requestBody: PostM2mClientRotateCancelRequest,
    ): CancelablePromise<PostM2mClientRotateCancelResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/m2m_client/{client_id}/rotate/cancel',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param clientId
     * @param requestBody
     * @returns PostM2mClientRotateStartResponse
     * @throws ApiError
     */
    public postM2MClientRotateStart(
        clientId: string,
        requestBody: PostM2mClientRotateStartRequest,
    ): CancelablePromise<PostM2mClientRotateStartResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/m2m_client/{client_id}/rotate/start',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
