import { CloudDownload } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useMemo } from "react";
import { useTheme } from "react-admin";
import Slider from "react-slick/lib/slider";

import MediaDisplay from "./MediaDisplay";

const MediaSlide = ({
  downloadAsset,
  mediaSrc,
  mediaType,
  description,
  customHeight = 400,
  onClick,
  descriptionStyle,
  customVideoErrorMessage,
  handleVideoErrorClick,
}: {
  downloadAsset?: () => void;
  mediaSrc: string;
  mediaType: "image" | "video";
  description?: string | JSX.Element;
  customHeight?: number;
  onClick?: () => void;
  descriptionStyle?: any;
  customVideoErrorMessage?: string;
  handleVideoErrorClick?: () => void;
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: `${customHeight}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {/* download icon */}

      {downloadAsset && (
        <Tooltip title="Download" placement="left">
          <IconButton
            onClick={downloadAsset}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "white",
              fontSize: "40px",
              backgroundColor: "rgba(255,255,255,0.5)",
              zIndex: 1,
            }}
            disableRipple
            size="small"
          >
            <CloudDownload color="secondary" />
          </IconButton>
        </Tooltip>
      )}

      {/* media display */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
          height: "400px",
        }}
      >
        <Box
          sx={{
            maxHeight: "100%",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MediaDisplay
            src={mediaSrc}
            mediaType={mediaType}
            onClick={onClick}
            onLoad={(e) => {
              const { naturalHeight, naturalWidth } =
                e.target as HTMLImageElement;
              if (mediaType === "image") {
                if (naturalHeight > naturalWidth) {
                  e.target.style.height = "100%";
                  e.target.style.width = "auto";
                  e.target.style.objectFit = "contain";
                } else {
                  e.target.style.width = "100%";
                  e.target.style.height = "auto";
                  e.target.style.objectFit = "contain";
                }
              } else {
                e.target.style.width = "100%";
                e.target.style.height = "100%";
                e.target.style.objectFit = "contain";
              }
            }}
            customVideoErrorMessage={customVideoErrorMessage}
            handleVideoErrorClick={handleVideoErrorClick}
          />
        </Box>
      </Box>

      {/* description */}
      {description && (
        <Typography
          variant="subtitle1"
          sx={{
            overflow: "auto",
            position: "relative",
            width: "100%",
            height: "auto",
            cursor: "pointer",
            transition: "height 0.3s, whiteSpace 0.3s",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            ...descriptionStyle,
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

const CustomSlider = ({
  items,
  maxItemsPerRow = 3,
  style,
}: {
  items: JSX.Element[];
  maxItemsPerRow?: number;
  style?: any;
}) => {
  const [theme] = useTheme() as any;
  const isSmall = useMediaQuery((theme as Theme).breakpoints.down("sm"));

  const slidesToShow = useMemo(() => {
    if (isSmall) {
      return 1;
    } else if (items.length < maxItemsPerRow) {
      return items.length;
    }
    return maxItemsPerRow;
  }, [isSmall, items.length, maxItemsPerRow]);

  if (slidesToShow === items.length) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "var(--page-max-width)",
        }}
      >
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Box>
    );
  }

  const settings = {
    infinite: false,
    dots: true,
    arrows: true,
    slidesPerRow: 1,
    slidesToShow,
    slidesToScroll: 1,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
        },
      },
    ],
    nextArrow: (
      <ArrowForwardIos
        sx={{
          color: "gray",
          backgroundColor: "transparent",
          borderRadius: "50%",
          width: 24,
          height: 24,
          zIndex: 2,
          marginRight: "2rem",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s ease",
          "&:hover": {
            color: "#1976d2",
          },
        }}
      />
    ),
    prevArrow: (
      <ArrowBackIos
        sx={{
          color: "gray",
          backgroundColor: "transparent",
          borderRadius: "50%",
          width: 24,
          height: 24,
          zIndex: 2,
          marginLeft: "2rem",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s ease",
          "&:hover": {
            color: "#1976d2",
          },
        }}
      />
    ),
    style: {
      ...style,
    },
  };

  return (
    <Slider {...settings}>
      {items.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </Slider>
  );
};

export default CustomSlider;

export { CustomSlider, MediaSlide };
