import dayjs from "dayjs";

import { IFieldErrors } from "./context";
import {
  validateContactName,
  validateGeocodedAddresses,
  validateOperationalArea,
} from "./utils";
import {
  stripPhoneNumberFormatting,
  validateEmail,
  validatePhone,
} from "../../common/utils";
import { IAlertProperties, IContact, PlaceType } from "../../types";

export enum ALERT_STEP {
  DETAILS = "Details",
  UPLOAD = "Upload",
  REVIEW = "Review",
}

const { DETAILS, UPLOAD, REVIEW } = ALERT_STEP;

export const alertSteps = [DETAILS, UPLOAD, REVIEW];

export const emptyContact: IContact = {
  email: "",
  phone_number: "",
  extension: "",
  name: "",
  badge_number: "",
  error: false,
};

export enum ALERT_CLASSIFICATION {
  PROTECTED_A = "Protected A",
  PROTECTED_B = "Protected B",
  UNCLASSIFIED = "Unclassified",
}

export enum LOCATION_CLASSIFICATION {
  INCIDENT_ADDRESS = "Incident Address",
  HOME_ADDRESS = "Home Address",
  WORK_ADDRESS = "Work Address",
  FREQUENTED_ADDRESS = "Frequented Address",
  LAST_SEEN_ADDRESS = "Last Seen Address",
  SAFE_HOUSE_ADDRESS = "Safe House Address",
  SURETY_ADDRESS = "Surety Address",
  SCHOOL_ADDRESS = "School Address",
  HIDEOUT_ADDRESS = "Hideout Address",
  VICTIM_ADDRESS = "Victim Address",
  CONTACT_ADDRESS = "Contact Address",
  ARREST_ADDRESS = "Arrest Address",
  OTHER = "Other",
}

export enum USER_TYPE {
  INDEPENDENT = "independent",
  REGULAR = "regular",
}

export const classificationDetails: {
  [key in ALERT_CLASSIFICATION]: JSX.Element;
} = {
  [ALERT_CLASSIFICATION.PROTECTED_B]: (
    <>
      Applies to information when unauthorized disclosure could reasonably be
      expected to cause <b>serious</b> injury outside the national interest.
    </>
  ),
  [ALERT_CLASSIFICATION.PROTECTED_A]: (
    <>
      Applies to information when unauthorized disclosure could reasonably be
      expected to cause <b>limited or moderate</b> injury outside the national
      interest.
    </>
  ),
  [ALERT_CLASSIFICATION.UNCLASSIFIED]: (
    <>
      Information that is not categorized as protected information or classified
      information. (Defence Terminology Bank record number 696380)
    </>
  ),
};

export const defaultAlertProperties: IAlertProperties = {
  policeService: null,
  occurrenceNumbers: [],
  incidentDate: null,
  type: null,
  associatedCrimes: [],
  description: null,
  tagDict: {},
  issuedTime: null,
  operationalArea: null,
  approvedBy: null,
  approvedByBadge: null,
  classification: ALERT_CLASSIFICATION.PROTECTED_B,
  contacts: [emptyContact],
  rawAddresses: [null] as unknown as PlaceType[],
  evidence_files: {},
  isDraft: true,
  pdf: {},
  resolutions: [],
};

export enum ALERT_STATUS {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}
export const generateValidationCriteria = async (
  alertProperties: IAlertProperties,
) => {
  const {
    policeService,
    operationalArea,
    occurrenceNumbers,
    incidentDate,
    type,
    associatedCrimes,
    description,
    contacts,
    rawAddresses,
    classification,
  } = alertProperties;

  const addressErrors: any[] = [];
  const res = await validateGeocodedAddresses(rawAddresses);
  res.forEach((address) => {
    addressErrors.push(address);
  });

  const validateContactEmail = (contact) => {
    if (contact.email === "" && contact.phone_number === "") {
      return true;
    } else if (contact.email !== "" && !validateEmail(contact.email)) {
      return true;
    } else {
      return false;
    }
  };

  const validateContactPhone = (contact) => {
    const strippedPhone = stripPhoneNumberFormatting(contact?.phone_number);
    if (contact.phone_number === "" && contact.email === "") {
      return true;
    } else if (contact.phone_number !== "" && !validatePhone(strippedPhone)) {
      return true;
    }
    return false;
  };
  const validationMap: IFieldErrors = {
    policeService: policeService ? false : true,
    operationalArea: policeService
      ? validateOperationalArea(policeService, operationalArea)
      : false,

    occurrenceNumbers: occurrenceNumbers.length === 0 ? true : false,
    classification:
      classification === ("" as ALERT_CLASSIFICATION) ||
      classification === null ||
      classification === undefined
        ? true
        : false,
    incidentDate:
      incidentDate === null ||
      !incidentDate.isValid() ||
      incidentDate.isAfter(new Date())
        ? true
        : false,
    type: type ? false : true,
    associatedCrimes: associatedCrimes.length === 0 ? true : false,
    description: description === "" || description === null ? true : false,
    addresses: addressErrors,
    contactEmail: contacts[0] ? validateContactEmail(contacts[0]) : false,
    contactPhone: contacts[0] ? validateContactPhone(contacts[0]) : false,
    contactName: validateContactName(contacts),
  };
  return validationMap;
};

export const defaultErrors: IFieldErrors = {
  policeService: false,
  operationalArea: false,
  occurrenceNumbers: false,
  incidentDate: false,
  type: false,
  associatedCrimes: false,
  description: false,
  addresses: [],
  contactEmail: false,
  contactPhone: false,
  classification: false,
  contactName: [],
};

export enum BULLETIN_TYPE {
  "Suspect(s) to Identify" = "Suspect(s) to Identify",
  "Vehicle(s) to Identify" = "Vehicle(s) to Identify",
  "Suspect(s) & Vehicle(s) to Identify" = "Suspect(s) & Vehicle(s) to Identify",
  "Police Information" = "Police Information",
  "Officer Safety" = "Officer Safety",
  "Property to Locate" = "Property to Locate",
  Wanted = "Wanted",
  Warrant = "Warrant",
  "Missing Person" = "Missing Person",
  Intelligence = "Intelligence",
  "Arrests Made" = "Arrests Made",
  Other = "Other",
}

export enum RESOLUTION_REASONS {
  "Suspect Identified" = "Suspect Identified",
  "Arrests Made" = "Arrests Made",
  "Arrest Made" = "Arrest Made",
  "Other" = "Other",
  "Vehicle Located" = "Vehicle Located",
  "Vehicle Owner Identified" = "Vehicle Owner Identified",
  "Suspect and Vehicle Identified" = "Suspect and Vehicle Identified",
  "Expired" = "Expired",
  "Withdrawn" = "Withdrawn",
  "Threat Level Updated" = "Threat Level Updated",
  "Threat Mitigated" = "Threat Mitigated",
  "Alert Withdrawn" = "Alert Withdrawn",
  "Warrant Served" = "Warrant Served",
  "Warrant Cancelled" = "Warrant Cancelled",
  "Missing Person Found" = "Missing Person Found",
  "Intelligence Expired" = "Intelligence Expired",
  "Intelligence Withdrawn" = "Intelligence Withdrawn",
}

export const shortenedResolutionReasons: Record<RESOLUTION_REASONS, string> = {
  "Suspect Identified": "Identified",
  "Arrests Made": "Arrested",
  "Arrest Made": "Arrested",
  Other: "Other",
  "Vehicle Located": "Located",
  "Vehicle Owner Identified": "Owner Identified",
  "Suspect and Vehicle Identified": "Identified",
  Expired: "Expired",
  Withdrawn: "Withdrawn",
  "Threat Level Updated": "Updated",
  "Threat Mitigated": "Mitigated",
  "Alert Withdrawn": "Withdrawn",
  "Warrant Served": "Served",
  "Warrant Cancelled": "Cancelled",
  "Missing Person Found": "Found",
  "Intelligence Expired": "Expired",
  "Intelligence Withdrawn": "Withdrawn",
};

export const bulletinResolutions: Record<BULLETIN_TYPE, RESOLUTION_REASONS[]> =
  {
    "Suspect(s) to Identify": [
      RESOLUTION_REASONS["Suspect Identified"],
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],
    "Vehicle(s) to Identify": [
      RESOLUTION_REASONS["Vehicle Located"],
      RESOLUTION_REASONS["Vehicle Owner Identified"],
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],
    "Suspect(s) & Vehicle(s) to Identify": [
      RESOLUTION_REASONS["Suspect and Vehicle Identified"],
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],
    "Police Information": [
      RESOLUTION_REASONS["Expired"],
      RESOLUTION_REASONS["Withdrawn"],
      RESOLUTION_REASONS["Other"],
    ],
    "Officer Safety": [
      RESOLUTION_REASONS["Threat Level Updated"],
      RESOLUTION_REASONS["Threat Mitigated"],
      RESOLUTION_REASONS["Alert Withdrawn"],
      RESOLUTION_REASONS["Other"],
    ],
    Wanted: [
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],

    Warrant: [
      RESOLUTION_REASONS["Warrant Served"],
      RESOLUTION_REASONS["Warrant Cancelled"],
      RESOLUTION_REASONS["Other"],
    ],
    "Missing Person": [
      RESOLUTION_REASONS["Missing Person Found"],
      RESOLUTION_REASONS["Other"],
    ],
    Intelligence: [
      RESOLUTION_REASONS["Intelligence Expired"],
      RESOLUTION_REASONS["Intelligence Withdrawn"],
      RESOLUTION_REASONS["Other"],
    ],
    "Arrests Made": [
      RESOLUTION_REASONS["Arrests Made"],
      RESOLUTION_REASONS["Arrest Made"],
      RESOLUTION_REASONS["Other"],
    ],
    Other: [RESOLUTION_REASONS["Other"]],
  };

export const crimeGroupedTypes = [
  // Person Crime
  { crime: "Assault", group: "Person Crime" },
  { crime: "Bodily Harm", group: "Person Crime" },
  { crime: "Indecent Exposure", group: "Person Crime" },
  { crime: "Missing Person", group: "Person Crime" },
  { crime: "Kidnapping", group: "Person Crime" },
  { crime: "Sexual Assault", group: "Person Crime" },
  { crime: "Attempt Murder", group: "Person Crime" },
  { crime: "Homicide", group: "Person Crime" },
  { crime: "Street Crime", group: "Person Crime" },
  { crime: "Other Person Crime", group: "Person Crime" },
  // Property Crime
  { crime: "Auto Theft", group: "Property Crime" },
  { crime: "Residential Break & Enter", group: "Property Crime" },
  { crime: "Commercial Break & Enter", group: "Property Crime" },
  { crime: "Theft Under", group: "Property Crime" },
  { crime: "Theft from Auto", group: "Property Crime" },
  { crime: "Theft", group: "Property Crime" },
  { crime: "Alcohol Theft (LCBO)", group: "Property Crime" },
  { crime: "Alcohol Theft (Other)", group: "Property Crime" },
  { crime: "Pump And Run", group: "Property Crime" },
  { crime: "Arson", group: "Property Crime" },
  { crime: "Shoplifting", group: "Property Crime" },
  { crime: "Distraction Theft", group: "Property Crime" },
  { crime: "Larceny", group: "Property Crime" },
  { crime: "Embezzlement", group: "Property Crime" },
  { crime: "Trespassing", group: "Property Crime" },
  { crime: "Mischief", group: "Property Crime" },
  { crime: "Recovered Property", group: "Property Crime" },
  { crime: "Other Property Crime", group: "Property Crime" },
  // Robbery Crime
  { crime: "Commercial Robbery", group: "Robbery" },
  { crime: "Financial Robbery", group: "Robbery" },
  { crime: "Carjacking Robbery", group: "Robbery" },
  { crime: "Taxi Robbery", group: "Robbery" },
  { crime: "Home Invasion Robbery", group: "Robbery" },
  { crime: "Mugging Robbery", group: "Robbery" },
  { crime: "Pursesnatch Robbery", group: "Robbery" },
  { crime: "Other Robbery", group: "Robbery" },
  // Organized Crime
  { crime: "Gang", group: "Organized Crime" },
  { crime: "Human Trafficking", group: "Organized Crime" },
  { crime: "Racketeering", group: "Organized Crime" },
  { crime: "Organized Theft", group: "Organized Crime" },
  { crime: "Money Laundering", group: "Organized Crime" },
  { crime: "Counterfeit Money", group: "Organized Crime" },
  { crime: "Other Organized Crime", group: "Organized Crime" },
  // Drugs Crime
  { crime: "Possession Of Drugs", group: "Drugs Crime" },
  { crime: "Manufacturing Of Drugs", group: "Drugs Crime" },
  { crime: "Distribution Of Drugs", group: "Drugs Crime" },
  { crime: "Drug Trafficking", group: "Drugs Crime" },
  { crime: "Other Drug Crime", group: "Drugs Crime" },
  // Computer Crime
  { crime: "Hacking", group: "Computer Crime" },
  { crime: "Cyberstalking", group: "Computer Crime" },
  { crime: "Online Fraud And Scams", group: "Computer Crime" },
  { crime: "Identity Theft", group: "Computer Crime" },
  { crime: "Other Computer Crimes", group: "Computer Crime" },
  // Other Crime
  { crime: "Fraud", group: "Other Crime" },
  { crime: "Vice", group: "Other Crime" },
  { crime: "Warrants", group: "Other Crime" },
  { crime: "Canada Wide Warrants", group: "Other Crime" },
  { crime: "Unlawfully At Large", group: "Other Crime" },
  { crime: "Evade Police/Police Pursuit", group: "Other Crime" },
  { crime: "Weapons", group: "Other Crime" },
  { crime: "Other Not on the List", group: "Other Crime" },
];
