import { Typography } from "@mui/material";
import {
  GoogleMap,
  LoadScriptProps,
  MarkerF,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useRef, useState } from "react";

import { serviceConfigurations } from "../../../common/serviceConfigurations";
import { ICoordinates, IPlace } from "../types";

const GOOGLE_PLACES_KEY = import.meta.env.VITE_GOOGLE_PLACES_KEY;
const mapLibraries: LoadScriptProps["libraries"] = ["places"];

const MapSearch: React.FC<any> = ({
  values,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  police,
  showMap = true,
  disabled = false,
}) => {
  // const { police } = useContext(OnlineReportingContext);

  if (!police) {
    // TODO: Map into some general police lookup of logged in user
    police = serviceConfigurations["Timmins Police Service"];
  }
  const [location, setLocation] = useState<ICoordinates>(
    values?.googleMapsPlace?.geometry
      ? values?.googleMapsPlace?.geometry
      : police?.mapCenter,
  );
  const [zoom, setZoom] = useState(values?.googleMapsPlace ? 16 : 12);
  // const [error, setError] = useState<string | null>(null);
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);
  const [addressText, setAddressText] = useState(
    values?.googleMapsPlace?.formatted_address
      ? values?.googleMapsPlace.formatted_address?.includes(
          values?.googleMapsPlace.name ? values.googleMapsPlace.name : "",
        )
        ? values?.googleMapsPlace.formatted_address
        : values?.googleMapsPlace.name +
          " - " +
          values?.googleMapsPlace.formatted_address
      : "",
  );

  const mapOptions = {
    streetViewControl: false, // Disable Street View
    tilt: 0,
    // Other map options...
  };

  const searchBoxOptions = {
    south: police.mapCenter.lat - 0.05,
    west: police.mapCenter.lng - 0.05,
    north: police.mapCenter.lat + 0.05,
    east: police.mapCenter.lng + 0.05,
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_PLACES_KEY,
    libraries: mapLibraries,
  });

  const updatePlacesDetails = function (place: google.maps.places.PlaceResult) {
    if (place.formatted_address?.includes(place.name ? place.name : ""))
      setAddressText(place.formatted_address);
    else setAddressText(place.name + " - " + place.formatted_address);

    const lat = place.geometry?.location?.lat() ?? 0;
    const lng = place.geometry?.location?.lng() ?? 0;

    if (lat && lng) {
      const selectedLocation = { lat, lng };

      const formattedPlace: IPlace = {
        address_components: place.address_components
          ? [...place.address_components]
          : [],
        formatted_address: place.formatted_address
          ? place.formatted_address
          : "",
        geometry: selectedLocation,
        name: place.name ? place.name : "",
        url: place.url ? place.url : "",
        types: place.types ? [...place.types] : [],
        place_id: place.place_id ? place.place_id : "",
      };
      setFieldValue("googleMapsPlace", formattedPlace);

      setLocation(selectedLocation);
      setZoom(16);
    }
  };

  const handlePlacesChanged = async () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];

      if (!place?.geometry?.location || !place?.address_components) {
        const service = new google.maps.places.PlacesService(
          document.createElement("div"),
        );
        service.getDetails(
          { placeId: place.place_id ?? "" },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && place) {
              updatePlacesDetails(place);
            }
          },
        );
        return;
      }
      updatePlacesDetails(place);
    }
  };

  const onLoadSearchBox = (ref: google.maps.places.SearchBox) => {
    searchBoxRef.current = ref;
  };

  return (
    <>
      {isLoaded ? (
        <div style={{ width: "100%" }}>
          {touched.googleMapsPlace && errors.googleMapsPlace && (
            <Typography sx={{ color: "red" }}>
              {errors.googleMapsPlace}
            </Typography>
          )}

          <StandaloneSearchBox
            onLoad={onLoadSearchBox}
            onPlacesChanged={handlePlacesChanged}
            bounds={searchBoxOptions}
          >
            <input
              value={addressText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddressText(event.target.value);
              }}
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
              type="text"
              name="googleMapsPlace"
              placeholder="Search address *"
              onBlur={handleBlur}
              disabled={disabled}
              autoComplete="disable"
              className={`sd-input sd-text ${touched.googleMapsPlace && errors.googleMapsPlace ? "sd-input--error" : ""}`}
              // add a clear button to the input field
            />
          </StandaloneSearchBox>

          {showMap && (
            <GoogleMap
              center={location}
              zoom={zoom}
              mapContainerStyle={{ height: "412px", width: "100%" }}
              options={mapOptions}
            >
              <MarkerF position={location} />
            </GoogleMap>
          )}
        </div>
      ) : (
        <div>Map Loading ...</div>
      )}
    </>
  );
};

export default MapSearch;
