import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";

import { defaultAlertProperties, defaultErrors } from "./constants";
import { updateToContextStructure } from "./utils";
import { apiAlertClient } from "../../common/api-client";
import { AuthStateContext } from "../../context";
import {
  BulletinPatchPayload,
  BulletinResourceResponse,
  IAlertGroup,
  IAlertProperties,
} from "../../types";
import { userPermissionChecks } from "../UserManagement/utils";

export const SingleAlertContext = createContext<{
  alertProperties: IAlertProperties;
  setAlertProperties: React.Dispatch<React.SetStateAction<IAlertProperties>>;
  setAlertProperty: (key: string, value: any) => void;
  errors: IFieldErrors;
  setErrors: React.Dispatch<React.SetStateAction<IFieldErrors>>;
  refreshAlertData: (id: string) => void;
}>({
  alertProperties: defaultAlertProperties,
  setAlertProperties: () => {},
  setAlertProperty: () => {},
  errors: defaultErrors,
  setErrors: () => {},
  refreshAlertData: () => {},
});

interface SingleAlertProviderProps {
  children: ReactNode;
}

export interface IFieldErrors {
  policeService: boolean;
  operationalArea: boolean;
  occurrenceNumbers: boolean;
  incidentDate: boolean;
  type: boolean;
  associatedCrimes: boolean;
  description: boolean;
  addresses: {
    address: any;
    isValid: boolean;
    hasLocationType: boolean;
  }[];
  contactEmail: boolean;
  contactPhone: boolean;
  contactName: boolean[];
  classification: boolean;
}

export const SingleAlertProvider: React.FC<SingleAlertProviderProps> = ({
  children,
}) => {
  const [alertProperties, setAlertProperties] = useState<IAlertProperties>(
    defaultAlertProperties,
  );
  const [errors, setErrors] = useState<IFieldErrors>(defaultErrors);

  const setAlertProperty = (key: string, value: any) => {
    setAlertProperties((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const refreshAlertData = (id: string) => {
    if (id) {
      apiAlertClient
        .getBulletinById(id)
        .then((res) => {
          const alertProperties = updateToContextStructure(res);
          setAlertProperties(alertProperties);
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  return (
    <SingleAlertContext.Provider
      value={{
        alertProperties,
        setAlertProperties,
        setAlertProperty,
        errors,
        setErrors,
        refreshAlertData,
      }}
    >
      {children}
    </SingleAlertContext.Provider>
  );
};

// Context for AlertView - All alerts page

interface IAlertViewContext {
  totalAlerts: number;
  setTotalAlerts: React.Dispatch<React.SetStateAction<number>>;
  currentItems: BulletinResourceResponse[];
  setCurrentItems: React.Dispatch<
    React.SetStateAction<BulletinResourceResponse[]>
  >;
  updatedItems: BulletinPatchPayload[];
  setUpdatedItems: React.Dispatch<React.SetStateAction<BulletinPatchPayload[]>>;
  policeServices: IAlertGroup[];
  setPoliceServices: React.Dispatch<React.SetStateAction<IAlertGroup[]>>;
  filtersVisible: boolean;
  setFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  bulletinId: number;
  setBulletinId: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const AlertViewContext = createContext<IAlertViewContext>({
  totalAlerts: 0,
  setTotalAlerts: () => {},
  currentItems: [],
  setCurrentItems: () => {},
  updatedItems: [],
  setUpdatedItems: () => {},
  policeServices: [],
  setPoliceServices: () => {},
  filtersVisible: false,
  setFiltersVisible: () => {},
  bulletinId: 0,
  setBulletinId: () => {},
  index: 0,
  setIndex: () => {},
});

// Provider for Search and Filter and AlertView Page

interface AlertViewProviderProps {
  children: ReactNode;
}

export const AlertViewProvider: React.FC<AlertViewProviderProps> = ({
  children,
}) => {
  const [alertView, setAlertView] = useState<"card" | "map" | "list">("card");
  const [totalAlerts, setTotalAlerts] = useState<number>(0);
  const [currentItems, setCurrentItems] = useState<BulletinResourceResponse[]>(
    [],
  );
  const [updatedItems, setUpdatedItems] = useState<BulletinPatchPayload[]>([]);
  const [policeServices, setPoliceServices] = useState<IAlertGroup[]>([]);

  const { currentUser, setCurrentUserGroups, setCurrentUserAgency } =
    useContext(AuthStateContext);

  useEffect(() => {
    if (currentUser) {
      const currentUserPermissions =
        currentUser.permissions?.map((permission) => permission.title) || [];

      const userPermissions = userPermissionChecks(currentUserPermissions);

      if (!userPermissions.canAccessAlertsApp) {
        return;
      }
      apiAlertClient.getPoliceServices().then((res) => {
        setPoliceServices(res);
      });
      apiAlertClient.getUserMeGroups().then((data) => {
        if (data) {
          const userGroups = data?.items;
          setCurrentUserGroups(userGroups);
          const foundAgency = userGroups?.find(
            (agency) => agency.is_police_service,
          );
          if (foundAgency) {
            setCurrentUserAgency(foundAgency);
          }
        }
      });
    }
  }, [currentUser]);

  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(
    totalAlerts === 0 && currentItems.length === 0,
  );
  const [index, setIndex] = useState<number>(0);
  const [bulletinId, setBulletinId] = useState<number>(currentItems[index]?.id);

  return (
    <AlertViewContext.Provider
      value={{
        totalAlerts,
        setTotalAlerts,
        currentItems,
        setCurrentItems,
        updatedItems,
        setUpdatedItems,
        policeServices,
        setPoliceServices,
        filtersVisible,
        setFiltersVisible,
        bulletinId,
        setBulletinId,
        index,
        setIndex,
      }}
    >
      {children}
    </AlertViewContext.Provider>
  );
};
