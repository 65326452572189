import {
  Chip,
  FormControl,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { matchSorter } from "match-sorter";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { AdvancedDetails } from "./AdvancedDetails";
import { ContactInfoBox } from "./ContactInfoBox";
import AddressList from "../../../common/AddressList";
import { colors } from "../../../common/colors";
import {
  IPoliceServiceConfiguration,
  serviceConfigurations,
} from "../../../common/serviceConfigurations";
import { IPoliceService } from "../../../types";
import "../alerts.scss";
import {
  ALERT_CLASSIFICATION,
  BULLETIN_TYPE,
  classificationDetails,
  crimeGroupedTypes,
  generateValidationCriteria,
} from "../constants";
import { AlertViewContext, SingleAlertContext } from "../context";

const filterOccuranceNumbers = createFilterOptions<{
  inputValue: string;
  label: string;
}>();

export const filterPoliceServiceOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue, { keys: ["name"] });

// Deptricated March 28, 2023
// export const crimeTypes = [
//   "Not Applicable",
//   "Arson",
//   "Attempt Murder or Homicide",
//   "Break & Enter",
//   "Counterfeit Money",
//   "Organized Crime",
//   "Theft",
//   "Fraud",
//   "Evade Police/Police Pursuit",
//   "Drugs",
//   "Human Trafficking",
//   "Mischief",
//   "Kidnapping",
//   "Sexual Assault",
//   "Stolen Vechicle",
//   "Trespassing",
//   "Unlawfully at Large",
//   "Canada Wide Warrants",
//   "Warrants",
//   "Weapons",
//   "Other",
// ];

interface IDetailsStepProps {
  validationAttempted: boolean;
  onPoliceServiceSelect: (policeService: IPoliceService) => void;
  setType: (type: string) => void;
  updateState: (key: string, value: any) => void;
  setIncidentDate: (date: any) => void;
  setDescription: (description: string) => void;
  type: string;
  associatedCrimes: any;
  occurrenceNumbers: any;
  incidentDate: any;
  description: string;
  policeService: IPoliceService;
  setErrors: (errors: any) => void;
}

export const DetailsStep = ({ ...props }: IDetailsStepProps) => {
  const { alertProperties, setAlertProperty, errors, setErrors } =
    useContext(SingleAlertContext);
  const { policeService, contacts, rawAddresses } = alertProperties;
  const serviceConfiguration: IPoliceServiceConfiguration = policeService?.name
    ? serviceConfigurations[policeService?.name]
    : ({} as IPoliceServiceConfiguration);

  const { policeServices } = useContext(AlertViewContext);

  const [addresses, setAddresses] = useState<any>(alertProperties.rawAddresses);

  useEffect(() => {
    console.log("updating context addresses", addresses);
    setAlertProperty("rawAddresses", addresses);
  }, [addresses]);

  useEffect(() => {
    console.log("updating local addresses", rawAddresses);
    setAddresses(rawAddresses);
  }, [rawAddresses]);

  const errorMessages = {
    policeService: "Please select a police service",
    type: "Please select a bulletin type",
    associatedCrimes: "Please select at least one crime",
    operationalArea: "Please select an operational area",
    occurrenceNumbers: "Please enter at least one occurrence number",
    description: "Please enter a description",
    // TODO: Add more error messages conditionally
    invalidAddress:
      "Please type in an address or click on the address and select from thedropdown",
    invalidLocationType: "Please select a location type",
    incidentDate: "Please select an incident date",
    classification: "Please select a classification",
  };

  const helperTextStyling = {
    style: {
      color: colors.ERROR_TEXT_RED,
      marginLeft: "10px",
    },
  };

  useEffect(() => {
    if (props.validationAttempted) {
      generateValidationCriteria(alertProperties).then((res) => {
        setErrors(res);
      });
    }
  }, [alertProperties]);

  const ClassificationDetails = ({
    classificationText,
  }: {
    classificationText: JSX.Element;
  }) => {
    return (
      <Typography
        variant="caption"
        style={{ color: "GrayText", lineHeight: "1" }}
      >
        {classificationText}{" "}
        <Link
          href="https://www.tbs-sct.canada.ca/pol/doc-eng.aspx?id=32614"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more here
        </Link>
      </Typography>
    );
  };

  return (
    <>
      <Paper elevation={4} className="alert-details-container">
        <div className="controls-row">
          <div className="control-parent">
            <FormControl fullWidth className="formControl">
              <Autocomplete
                id="police-service-select-autocomplete"
                options={policeServices as any}
                getOptionLabel={(option: IPoliceService) =>
                  option?.name ? option.name : ""
                }
                value={policeService}
                autoComplete
                autoHighlight
                clearOnBlur
                filterSelectedOptions
                fullWidth
                openOnFocus
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                style={{
                  minWidth: "200px",
                }}
                filterOptions={filterPoliceServiceOptions as any}
                size="small"
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    props.onPoliceServiceSelect(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.policeService}
                    required
                    label="Issuing Police Service"
                    variant="outlined"
                    margin="none"
                    helperText={
                      errors?.policeService ? errorMessages.policeService : ""
                    }
                    FormHelperTextProps={helperTextStyling}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.name, inputValue, {
                    insideWords: true,
                  });
                  const parts = parse(option.name, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
            </FormControl>
          </div>

          <div className="control-parent">
            <FormControl fullWidth className="formControl">
              <Autocomplete
                id="select-bulltin-type"
                options={Object.values(BULLETIN_TYPE)}
                value={props.type}
                autoComplete
                autoHighlight
                filterSelectedOptions
                clearOnBlur
                fullWidth
                openOnFocus
                style={{
                  minWidth: "200px",
                }}
                size="small"
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    props.setType(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.type}
                    required
                    label="Bulletin Type"
                    variant="outlined"
                    margin="none"
                    helperText={errors?.type ? errorMessages.type : ""}
                    FormHelperTextProps={helperTextStyling}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option, inputValue, {
                    insideWords: true,
                  });
                  const parts = parse(option, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
            </FormControl>
          </div>
        </div>

        <div className="controls-row">
          <div className="control-parent">
            <FormControl fullWidth className="formControl">
              <Autocomplete
                multiple
                id="select-crime-types"
                options={crimeGroupedTypes}
                groupBy={(option) => option.group}
                getOptionLabel={(option) => option.crime}
                value={props.associatedCrimes}
                isOptionEqualToValue={(option, value) =>
                  option.crime === value.crime
                }
                filterSelectedOptions
                autoComplete
                autoHighlight
                clearOnBlur
                fullWidth
                openOnFocus
                // disableCloseOnSelect
                size="small"
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.crime}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event, newValue) => {
                  props.updateState("associatedCrimes", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.associatedCrimes}
                    label="Associated Crimes"
                    variant="outlined"
                    placeholder="Select All That Apply"
                    required
                    margin="none"
                    helperText={
                      errors?.associatedCrimes
                        ? errorMessages.associatedCrimes
                        : ""
                    }
                    FormHelperTextProps={helperTextStyling}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.crime, inputValue, {
                    insideWords: true,
                  });
                  const parts = parse(option.crime, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
            </FormControl>
          </div>
        </div>

        {serviceConfiguration && serviceConfiguration.operationalArea && (
          <div className="controls-row">
            <div className="control-parent">
              <FormControl fullWidth className="formControl">
                <Autocomplete
                  id="operational-area"
                  options={serviceConfiguration.operationalArea}
                  value={alertProperties.operationalArea}
                  autoComplete
                  autoHighlight
                  filterSelectedOptions
                  clearOnBlur
                  fullWidth
                  openOnFocus
                  style={{
                    minWidth: "200px",
                  }}
                  size="small"
                  onChange={(event, newValue) => {
                    props.updateState("operationalArea", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors?.operationalArea}
                      required
                      label="Operational Area"
                      variant="outlined"
                      margin="none"
                      helperText={
                        errors?.operationalArea
                          ? errorMessages.operationalArea
                          : ""
                      }
                      FormHelperTextProps={helperTextStyling}
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue, {
                      insideWords: true,
                    });
                    const parts = parse(option, matches);

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}

        <div className="controls-row">
          <div className="control-parent">
            <FormControl fullWidth className="formControl">
              <Autocomplete
                id="occurrence-number-fill"
                options={[]}
                autoHighlight
                fullWidth
                freeSolo
                multiple
                autoSelect
                value={props.occurrenceNumbers}
                size="small"
                onChange={(event, newValue) => {
                  if (newValue[newValue.length - 1]?.inputValue) {
                    const newOccurenceNum = newValue[
                      newValue.length - 1
                    ].inputValue.replace(/\s+/g, "");
                    const duplicate = newValue
                      .slice(0, newValue.length - 1)
                      .find((value) => value === newOccurenceNum);
                    if (duplicate) {
                      toast.warning("Duplicate Occurence Number");
                    } else {
                      props.updateState("occurrenceNumbers", [
                        ...newValue.slice(0, newValue.length - 1),
                        newOccurenceNum,
                      ]);
                    }
                  } else props.updateState("occurrenceNumbers", newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                filterOptions={(options, params) => {
                  const filtered = filterOccuranceNumbers(options, params);
                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      label: `Press Enter or Click to add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.label;
                  }
                  // Regular option
                  return option;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors?.occurrenceNumbers}
                    required
                    label="Occurrence Number(s)"
                    variant="outlined"
                    margin="none"
                    helperText={
                      errors?.occurrenceNumbers
                        ? errorMessages.occurrenceNumbers
                        : ""
                    }
                    FormHelperTextProps={helperTextStyling}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>

        <div className="controls-row">
          <div className="control-parent">
            <DateTimePicker
              label="Incident Date/Time"
              format="YYYY-MMM-DD   hh:mm A"
              ampm
              slotProps={{
                textField: {
                  size: "small",
                  variant: "outlined",
                  required: true,
                  fullWidth: true,
                  error: errors?.incidentDate,
                },
              }}
              value={props?.incidentDate ? props?.incidentDate : ""}
              disableFuture
              onChange={(newValue) => {
                props.setIncidentDate(newValue);
              }}
            />
            {errors?.incidentDate && (
              <Typography
                variant="caption"
                style={{
                  color: colors.ERROR_TEXT_RED,
                  marginLeft: "10px",
                }}
              >
                {errors?.incidentDate ? errorMessages.incidentDate : ""}
              </Typography>
            )}
          </div>
        </div>

        <div className="controls-row">
          <div className="control-parent">
            <AddressList
              addresses={addresses}
              setAddresses={setAddresses}
              errors={errors?.addresses}
            />
          </div>
        </div>

        <div className="controls-row">
          <div className="control-parent">
            <TextField
              id="date"
              label="Description"
              type="text"
              variant="outlined"
              fullWidth
              required
              multiline
              margin="none"
              minRows={4}
              className="text-input-mine"
              error={errors?.description}
              value={alertProperties.description}
              onChange={(ev) => {
                props.updateState("description", ev.target.value);
              }}
              FormHelperTextProps={helperTextStyling}
              helperText={errors?.description ? errorMessages.description : ""}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
      </Paper>

      <Paper elevation={4} className="alert-details-container">
        <FormControl fullWidth className="formControl">
          <Autocomplete
            id="classification-select"
            options={Object.values(ALERT_CLASSIFICATION) as any}
            value={alertProperties.classification}
            autoComplete
            autoHighlight
            filterSelectedOptions
            clearOnBlur
            fullWidth
            openOnFocus
            size="small"
            onChange={(event, newValue) => {
              setAlertProperty("classification", newValue);
            }}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                  label="Classification"
                  variant="outlined"
                  margin="none"
                  required
                  FormHelperTextProps={helperTextStyling}
                  helperText={
                    errors?.classification ? errorMessages.classification : ""
                  }
                  error={errors?.classification}
                />
                {
                  <ClassificationDetails
                    classificationText={
                      classificationDetails[alertProperties.classification]
                    }
                  />
                }{" "}
              </div>
            )}
          />
        </FormControl>
        <ContactInfoBox
          contacts={contacts}
          setContacts={(contacts: any) => {
            setAlertProperty("contacts", contacts);
          }}
          policeService={policeService}
        />
        <div className="approved-by-container">
          <Typography>Approved By:</Typography>
          <div className="controls-row">
            <div className="control-parent">
              <div className="controls-row-related">
                <div className="control-parent1 ">
                  <TextField
                    label="Name"
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className="text-input-mine"
                    name="approved_by"
                    value={alertProperties.approvedBy}
                    placeholder="e.g. Cst. John Smith"
                    onChange={(ev) => {
                      props.updateState("approvedBy", ev.target.value);
                    }}
                  />
                </div>
                <div className="control-parent2 ">
                  <TextField
                    label="Badge #"
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className="text-input-mine"
                    name="approved_by_badge"
                    value={alertProperties.approvedByBadge}
                    onChange={(ev) => {
                      props.updateState("approvedByBadge", ev.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdvancedDetails />
      </Paper>
    </>
  );
};
